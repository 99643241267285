import React from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ideastoken from "../assets/ideastoken.jpg";
import ichtus from "../assets/ichtus.jpg";
import AEG from "../assets/AEG.png";
import freelancer from "../assets/freelancer.png";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const data = [
  {
    id: 1,
    title: "Front end web developer - Ideas Token",
    date: "JAN 2022 - Present",
    image: ideastoken,
    description:
      "Developing the front-end of the main website , lottery function and the platform. ",
  },
  {
    id: 2,
    title: "Full Stack developer (STAGE) - ICHTUS IT ",
    date: "DEC 2022 - FEB 2023",
    image: ichtus,
    description: (
      <p style={{ whiteSpace: "pre-line" }}>
        Perform unit tests on the backend. <br /> Fix SMTP for sending mails
        using nodemailer.
        <br /> Fetching the APIs on Frontend using reactjs.
      </p>
    ),
  },
  {
    id: 3,
    title: "Freelance Full-Stack Developer",
    date: "JAN 2020 - JAN 2021",
    image: freelancer,
    description: (
      <p style={{ whiteSpace: "pre-line" }}>
        Create a website or a desktop application.
        <br /> Create a project using Arduino.
      </p>
    ),
  },
  {
    id: 4,
    title: "Front end web developer (STAGE) - AEG Beirut",
    date: "JUL 2021 - AUG 2021",
    image: AEG,
    description:
      "Create a new design for AEG website using HTML5 , CSS , BOOTSTRAP and JAVASCRIPT. ",
  },
];
const Experience = () => {
  return (
    <div id="Experience">
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {" "}
        <Typography
          variant="h3"
          gutterBottom
          style={{
            marginTop: "4rem ",
            textAlign: "center",
            color: "gold",
          }}
        >
          Experience
        </Typography>
        <Grid container spacing={3}>
          {data.map((experience) => (
            <Grid item xs={12} sm={6} align="center" key={experience.id}>
              <Card
                sx={{ maxWidth: 345 }}
                style={{
                  boxShadow:
                    "0px 0px 10px 0px rgba(0,0,0,0.2), 0px 0px 20px 0px rgba(0,0,0,0.19)",
                  border: "3px solid  darkblue",
                }}
              >
                <CardMedia
                  sx={{ height: "10rem", width: "50%" }}
                  image={experience.image}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {experience.title}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="div">
                    {experience.company}
                  </Typography>
                  <Typography gutterBottom variant="overline" component="div">
                    {experience.date}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {experience.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Experience;
