import React from "react";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { experimentalStyled as styled } from "@mui/material/styles";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Skills = () => {
  return (
    <div id="Skills">
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {" "}
        <Typography
          variant="h3"
          gutterBottom
          style={{
            marginTop: "4rem ",
            textAlign: "center",
            color: "gold",
          }}
        >
          Skills
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
        >
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  // marginTop: "4rem ",
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Web Development
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                HTML5 , CSS3 , Bootstrap , JavaScript, NodeJS, PHP, Angular,
                ReactJS
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Windows App Development
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                C/C++ ,C#, .NET ,Java , Python
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Databases
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                MySQL , Oracle, PL/Sql, MongoDB , PostgreSQL
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Operating System{" "}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                WINDOWS , UBUNTU
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Software Design{" "}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                UML
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={0}>
            <Item>
              {" "}
              <Typography
                variant="h6"
                gutterBottom
                style={{
                  textAlign: "center",
                  color: "#4d6d78",
                }}
              >
                Source Code & Project Management
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                <b>Source Code Management</b>: Git
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                <b> Project Management </b>: Agile, Scrum, Trello Board
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Skills;
