import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// import { makeStyles } from "@mui/material";
// const useStyles = makeStyles({
//   Menu: {
//     backgroundColor: "gris",
//   },
// });
function NavBarDrawer() {
  // const classes = useStyles();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "gold",
              textDecoration: "none",
              backgroundColor: "black",
            }}
          >
            Mostapha Youssef
          </Typography>
          {/* ================================ */}
          {/* Mobile version */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
              // className={classes.Menu}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <a
                    href="#About"
                    className="hover:underline"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    About
                  </a>
                </Typography>
                <Divider />
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  {" "}
                  <a
                    href="#Education"
                    className="hover:underline"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Education
                  </a>
                </Typography>
                <Divider />
              </MenuItem>{" "}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  {" "}
                  <a
                    href="#Skills"
                    className="hover:underline"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Skills
                  </a>
                </Typography>
                <Divider />
              </MenuItem>{" "}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  {" "}
                  <a
                    href="#Experience"
                    className="hover:underline"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Experience
                  </a>
                </Typography>
                <Divider />
              </MenuItem>{" "}
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  {" "}
                  <a
                    href="#Contact"
                    className="hover:underline"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    Contact
                  </a>
                </Typography>
                <Divider />
              </MenuItem>{" "}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              fontSize: "1.1rem",
              color: "gold",
              textDecoration: "none",
              backgroundColor: "black",
            }}
          >
            Mostapha Youssef
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", backgroundColor: "black" },
            }}
          >
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="#About"
                className="hover:underline"
                style={{ color: "white", textDecoration: "none" }}
              >
                About
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="#Education"
                className="hover:underline"
                style={{ color: "white", textDecoration: "none" }}
              >
                Education
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="#Skills"
                className="hover:underline"
                style={{ color: "white", textDecoration: "none" }}
              >
                Skills
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="#Experience"
                className="hover:underline"
                style={{ color: "white", textDecoration: "none" }}
              >
                Experience
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="#Contact"
                className="hover:underline"
                style={{ color: "white", textDecoration: "none" }}
              >
                Contact
              </a>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBarDrawer;
