import React from "react";
import {
  Grid,
  Typography,
  // Button
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
// import TextField from "@mui/material/TextField";
// import SendIcon from "@mui/icons-material/Send";
// import emailjs from "@emailjs/browser";
// import EmailIcon from "@mui/icons-material/Email";
// import PhoneIcon from "@mui/icons-material/Phone";
// import HomeIcon from "@mui/icons-material/Home";
const Contact = () => {
  // const form = useRef();
  // const [name, setName] = useState("");
  // const [subject, setSubject] = useState("");
  // const [Email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_y67x65v", //service_y67x65v
  //       "template_qw49ixi",
  //       {
  //         name,
  //         Email,
  //         subject,
  //         message,
  //         // receiverEmail: "mostaphayessf14@gmail.com", // set the receiver email to your desired email address
  //       },
  //       // form.current,
  //       "eXFw8H6UOiWt3cSh0nYoM" //eXFw•••••••••••••••••
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   e.target.reset();
  // };
  return (
    <div id="Contact">
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {/* <Typography
          variant="h3"
          gutterBottom
          style={{
            marginTop: "4rem ",
            textAlign: "center",
            color: "gold",
          }}
        >
          Contact
        </Typography>{" "} */}
        <iframe
          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40214.88456415801!2d1.8688621499999998!3d50.95274835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dc3f79241be1cf%3A0x1c0af141f1252d10!2s62100%20Calais%2C%20France!5e0!3m2!1sen!2slb!4v1676327556594!5m2!1sen!2slb`}
          title="Map"
          width="100%"
          height="600"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          style={{ marginTop: "3rem" }}
        />{" "}
        <CssBaseline />
        <Container maxWidth="sm" style={{ position: "absolute" }}>
          <Box
            sx={{ bgcolor: "white", height: "25rem", marginTop: "2rem" }}
            component="form"
            noValidate
            autoComplete="off"
            // ref={form}
            // onSubmit={sendEmail}
          >
            <Typography
              variant="h5"
              gutterBottom
              style={{
                marginTop: "4rem ",
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Contact Me
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginTop: "1.5rem ",
                textAlign: "center",
                color: "black",
              }}
            >
              You have a project or an idea that I can help you to implement?
              Just contact me and I'll get back to you in 24 hours with my
              availability and we'll discuss the details.
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginTop: "1.5rem ",
                textAlign: "center",
                color: "black",
              }}
            >
              {/* <EmailIcon fontSize="small" /> */}
              Email : contact@mostapha-youssef.com
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginTop: "1.5rem ",
                textAlign: "center",
                color: "black",
              }}
            >
              {/* <PhoneIcon fontSize="small" /> */}
              Phone : +33 7 76 96 57 30
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginTop: "1.5rem ",
                textAlign: "center",
                color: "black",
              }}
            >
              {/* <HomeIcon fontSize="small" /> */}
              Address : 19 rue Masséna CALAIS 62100
            </Typography>
            {/* <TextField
              required
              id="standard-required"
              label="Full Name"
              defaultValue=""
              variant="standard"
              style={{ marginLeft: "2rem", width: "60%" }}
              onChange={(event) => setName(event.target.value)}
            />
            <br />
            <TextField
              required
              id="standard-required"
              label="E-mail"
              defaultValue=""
              variant="standard"
              style={{ marginLeft: "2rem", width: "60%" }}
              onChange={(event) => setEmail(event.target.value)}
            />
            <br />

            <TextField
              required
              id="standard-required"
              label="Subject"
              defaultValue=""
              variant="standard"
              style={{ marginLeft: "2rem", width: "60%" }}
              onChange={(event) => setSubject(event.target.value)}
            />
            <TextField
              required
              id="standard-required"
              label="Message"
              defaultValue=""
              variant="standard"
              style={{ marginLeft: "2rem", width: "60%", height: "10%" }}
              rows={3}
              multiline
              onChange={(event) => setMessage(event.target.value)}
            />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              style={{ marginTop: "7rem", backgroundColor: "black" }}
              type="submit"
              // onSubmit={sendEmail}
            >
              Send
            </Button> */}
          </Box>
        </Container>
      </Grid>
    </div>
  );
};

export default Contact;
