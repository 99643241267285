import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import Home from "../features/home/Home";
import loginAdmin from "../pages/Admin/login";
const theme = createTheme({
  palette: {
    primary: {
      main: "#593bdb",
    },
    secondary: {
      main: "#292c35",
    },
  },
  typography: {
    fontFamily: [
      "Cairo",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const index = () => {
  return (
    <Router forceRefresh={true}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LinearProgress />}>
          <Routes element={<Home />}>
            <Route index path="/" element={<Home />} />
            <Route path="/adminDashboard" element={<loginAdmin />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default index;
