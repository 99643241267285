import React from "react";
import { Grid, Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import UA from "../assets/UA.jpg";
import EILCO from "../assets/EILCO.png";
import PUT from "../assets/Put.png";
import AOS from "aos";

import "aos/dist/aos.css";

AOS.init();
const Education = () => {
  return (
    <div
      id="Education"
      data-aos="fade-down-right"
      data-aos-delay="1000"
      data-aos-duration="5000"
      // data-aos-easing="ease-in-out"
    >
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {" "}
        <Typography
          variant="h3"
          gutterBottom
          style={{
            marginTop: "4rem ",
            textAlign: "center",
            color: "gold",
          }}
        >
          Education
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} align="center">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  marginLeft: "5rem",
                  width: "80%",
                  height: "25rem",
                },
              }}
            >
              <Paper
                elevation={3}
                style={{
                  boxShadow:
                    "0px 0px 10px 0px rgba(0,0,0,0.2), 0px 0px 20px 0px rgba(0,0,0,0.19)",
                  border: "3px solid blue",
                  marginRight: "2.5rem",
                }}
              >
                <img
                  src={UA}
                  alt="UA"
                  style={{ width: "50%", justifyContent: "center" }}
                />
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "2rem ",
                    textAlign: "center",
                  }}
                >
                  Faculty of Engineering - Antonine University <br />{" "}
                  [2018-2021]
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Bachelor's Degree [5 Semesters ]
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "lighter",
                  }}
                >
                  SOFTWARE ENGINEERING
                </Typography>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} align="center">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  marginLeft: "5rem",

                  width: "80%",
                  height: "25rem",
                },
              }}
            >
              <Paper
                elevation={3}
                style={{
                  boxShadow:
                    "0px 0px 10px 0px rgba(0,0,0,0.2), 0px 0px 20px 0px rgba(0,0,0,0.19)",
                  border: "3px solid tomato",
                  marginRight: "2.5rem",
                }}
              >
                <img
                  src={EILCO}
                  alt="EILCO"
                  style={{
                    width: "50%",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                  }}
                />{" "}
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "2rem ",
                    textAlign: "center",
                  }}
                >
                  EILCO - Ecole d'Ingénieurs du Littoral Côte d'Opale <br />{" "}
                  [2021-2024]
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Engineer's Degree
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "lighter",
                  }}
                >
                  SOFTWARE ENGINEERING
                </Typography>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} align="center">
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  marginLeft: "5rem",

                  width: "80%",
                  height: "25rem",
                },
              }}
            >
              <Paper
                elevation={3}
                style={{
                  boxShadow:
                    "0px 0px 10px 0px rgba(0,0,0,0.2), 0px 0px 20px 0px rgba(0,0,0,0.19)",
                  border: "3px solid gold",
                  marginRight: "2.5rem",
                }}
              >
                <img
                  src={PUT}
                  alt="PUT"
                  style={{
                    width: "27%",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                  }}
                />{" "}
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "2rem ",
                    textAlign: "center",
                  }}
                >
                  Poznań University of Technology <br /> [Present]
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Erasmus Programme
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{
                    marginTop: "3rem ",
                    textAlign: "center",
                    fontWeight: "lighter",
                  }}
                >
                  SOFTWARE ENGINEERING
                </Typography>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Education;
