import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Footer = () => {
  return (
    <Grid container justifyContent={"center"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <Typography
          level="body3"
          justifyContent="center"
          sx={{ ml: "auto" }}
          mb={8}
          style={{ color: "white" }}
        >
          © 2023 - Mostapha YOUSSEF - Version 1.2
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;
