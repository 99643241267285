import React from "react";
import AboutCard from "../../components/AboutCard";
import NavBarDrawer from "../../components/NavBarDrawer";
import Education from "../../components/Education";
import Skills from "../../components/Skills";
import Experience from "../../components/Experience";
import Footer from "../../components/Footer";
import { Divider } from "@mui/material";
import Contact from "../../components/Contact";
import kommunicateChat from "../chat";

const Home = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <NavBarDrawer />
      <AboutCard />
      <Education />
      <Skills />
      <Experience />
      <Contact />
      <Divider sx={{ my: 2 }} style={{ color: "gold" }} />
      <Footer />
      <kommunicateChat />
    </div>
  );
};

export default Home;
