import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid, Button } from "@mui/material";
import CVENG from "../assets/CV/CVENG.pdf";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Link from "@mui/material/Link";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

export default function AboutCard() {
  const handleClick = () => {
    window.open(CVENG, "_blank");
  };
  return (
    <div id="About" data-aos="fade-right">
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Card
          sx={{
            marginTop: "10rem",
            width: "85%",
          }}
        >
          <CardContent
            style={{
              boxShadow:
                "0px 0px 10px 0px rgba(0,0,0,0.2), 0px 0px 20px 0px rgba(0,0,0,0.19)",
              border: "3px solid gold",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" fontWeight={"bold"} gutterBottom>
                  About
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Hello world! My name Mostapha YOUSSEF and I am currently 22
                  years of age.
                  <br />
                  As a student of software engineering from Lebanon, I possess a
                  solid 2 years of experience in web development. My academic
                  pursuits and professional endeavors have fueled my fascination
                  with emerging technologies, such as robotics, artificial
                  intelligence, and web development, among other things.
                  <br /> With my exceptional communication and interpersonal
                  abilities, coupled with a dedicated work ethic, I thrive in
                  both collaborative and independent work settings, ultimately
                  contributing to the success of products and optimizing
                  processes.
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Follow Me
                </Typography>
                <div>
                  <Link
                    href="https://github.com/MostaphaY8"
                    target={"_blank"}
                    style={{ color: "black" }}
                  >
                    <GitHubIcon fontSize="medium" />
                  </Link>

                  <Link
                    href="https://www.linkedin.com/in/mostaphayf/"
                    target={"_blank"}
                    style={{ color: "blue" }}
                  >
                    <LinkedInIcon fontSize="medium" />
                  </Link>
                </div>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginTop: "1rem",
                  }}
                  onClick={handleClick}
                >
                  Donwload CV
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
